var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('b-avatar',{attrs:{"src":_vm.avatar,"size":"130px"}}),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.file.$el.click()}}},[_vm._v(" Ubah Foto Profil ")]),_c('b-form-file',{ref:"file",attrs:{"accept":".jpg, .png, .gif","hidden":true,"plain":""},model:{value:(_vm.avatar_new),callback:function ($$v) {_vm.avatar_new=$$v},expression:"avatar_new"}}),_c('b-card-text',[_vm._v("Allowed JPG, GIF or PNG. Max size of 2Mb")])],1)],1)],1),_c('b-row',{staticStyle:{"margin-top":"40px"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"userInformation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.uploadAvatar($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Depan","label-for":"v-first-name"}},[_c('validation-provider',{attrs:{"name":"Nama Depan","vid":"project-name","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-first-name","state":errors.length > 0 ? false:null,"placeholder":"Masukkan Nama Depan"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Belakang","label-for":"v-first-name"}},[_c('validation-provider',{attrs:{"name":"Nama Belakang","vid":"project-name","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-first-name","state":errors.length > 0 ? false:null,"placeholder":"Masukkan Nama Belakang"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Alamat Email","label-for":"v-email"}},[_c('validation-provider',{attrs:{"name":"Alamat Email","vid":"email","rules":"required|email","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-email","state":errors.length > 0 ? false:null,"type":"text","placeholder":"Masukkan Alamat Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.emailError))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"No. Handphone","label-for":"v-mobile"}},[_c('validation-provider',{attrs:{"name":"No. Handphone","vid":"phone","rules":"required|integer|min:8","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-mobile","type":"text","state":errors.length > 0 ? false:null,"placeholder":"Masukkan No. Handphone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('hr'),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"v-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"password","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"v-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"placeholder":"Masukkan Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Konfirmasi Password","label-for":"v-password-confirm"}},[_c('validation-provider',{attrs:{"name":"Konfirmasi Password","vid":"password-confirm","rules":_vm.isRequired,"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"v-password-confirm","state":errors.length > 0 ? false:null,"type":_vm.confirmPasswordFieldType,"placeholder":"Masukkan Konfirmasi Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.confirmPasswordToggleIcon},on:{"click":_vm.toggleConfirmPassword}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }