<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-card-body>
                    <b-row>
                        <b-col cols="12" class="text-center">
                                <b-avatar :src="avatar" class="" size="130px"></b-avatar>
                                <b-media-body class="mt-75 ml-75">
                                    <!-- upload button -->
                                    <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    size="sm"
                                    class="mb-75 mr-75"
                                    @click="$refs.file.$el.click()"
                                    >
                                    Ubah Foto Profil
                                    </b-button>
                                    <b-form-file
                                    ref="file"
                                    accept=".jpg, .png, .gif"
                                    :hidden="true"
                                    plain
                                    v-model="avatar_new"
                                    />
                                    <!--/ upload button -->
                                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 2Mb</b-card-text>
                                </b-media-body>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 40px">
                        <b-col cols="12">
                            <validation-observer
                                ref="userInformation"
                            >
                                <b-form @submit.prevent="uploadAvatar">
                                    <b-row>
                                        <!-- Nama Depan -->
                                        <b-col cols="12">
                                            <b-form-group
                                            label="Nama Depan"
                                            label-for="v-first-name"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Nama Depan"
                                                    vid="project-name"
                                                    rules="required"
                                                    :custom-messages="customMessages"
                                                    >
                                                    <b-form-input
                                                        id="v-first-name"
                                                        :state="errors.length > 0 ? false:null"
                                                        v-model="firstName"
                                                        placeholder="Masukkan Nama Depan"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <!-- Nama Belakang -->
                                        <b-col cols="12">
                                            <b-form-group
                                            label="Nama Belakang"
                                            label-for="v-first-name"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Nama Belakang"
                                                    vid="project-name"
                                                    rules="required"
                                                    :custom-messages="customMessages"
                                                    >
                                                    <b-form-input
                                                        id="v-first-name"
                                                        :state="errors.length > 0 ? false:null"
                                                        v-model="lastName"
                                                        placeholder="Masukkan Nama Belakang"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <!-- email -->
                                        <b-col cols="12">
                                            <b-form-group
                                            label="Alamat Email"
                                            label-for="v-email"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Alamat Email"
                                                    vid="email"
                                                    rules="required|email"
                                                    :custom-messages="customMessages"
                                                    >
                                                    <b-form-input
                                                        id="v-email"
                                                        :state="errors.length > 0 ? false:null"
                                                        type="text"
                                                        v-model="email"
                                                        placeholder="Masukkan Alamat Email"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                    <small class="text-danger">{{ emailError }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <!-- mobile -->
                                        <b-col cols="12">
                                            <b-form-group
                                            label="No. Handphone"
                                            label-for="v-mobile"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="No. Handphone"
                                                    vid="phone"
                                                    rules="required|integer|min:8"
                                                    :custom-messages="customMessages"
                                                    >
                                                    <b-form-input
                                                        id="v-mobile"
                                                        type="text"
                                                        :state="errors.length > 0 ? false:null"
                                                        v-model="phoneNumber"
                                                        placeholder="Masukkan No. Handphone"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <hr>

                                        <!-- password -->
                                        <b-col cols="12">
                                            <b-form-group
                                            label="Password"
                                            label-for="v-password"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Password"
                                                    vid="Password"
                                                    rules="password"
                                                    :custom-messages="customMessages"
                                                    >
                                                    <b-input-group
                                                        class="input-group-merge"
                                                        :class="errors.length > 0 ? 'is-invalid':null"
                                                    >
                                                        <b-form-input
                                                        id="v-password"
                                                        v-model="password"
                                                        :state="errors.length > 0 ? false:null"
                                                        class="form-control-merge"
                                                        :type="passwordFieldType"
                                                        placeholder="Masukkan Password"
                                                        />
                                                        <b-input-group-append is-text>
                                                        <feather-icon
                                                            class="cursor-pointer"
                                                            :icon="passwordToggleIcon"
                                                            @click="togglePasswordVisibility"
                                                        />
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <!-- password -->
                                        <b-col cols="12">
                                            <b-form-group
                                            label="Konfirmasi Password"
                                            label-for="v-password-confirm"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Konfirmasi Password"
                                                    vid="password-confirm"
                                                    :rules="isRequired"
                                                    :custom-messages="customMessages"
                                                    >
                                                    <b-input-group
                                                        class="input-group-merge"
                                                        :class="errors.length > 0 ? 'is-invalid':null"
                                                    >
                                                        <b-form-input
                                                        id="v-password-confirm"
                                                        v-model="confirmPassword"
                                                        :state="errors.length > 0 ? false:null"
                                                        class="form-control-merge"
                                                        :type="confirmPasswordFieldType"
                                                        placeholder="Masukkan Konfirmasi Password"
                                                        />
                                                        <b-input-group-append is-text>
                                                        <feather-icon
                                                            class="cursor-pointer"
                                                            :icon="confirmPasswordToggleIcon"
                                                            @click="toggleConfirmPassword"
                                                        />
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <!-- submit and reset -->
                                        <b-col cols="12">
                                            <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            type="submit"
                                            variant="primary"
                                            class="mr-1"
                                            >
                                            Simpan
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

import {
    BRow, BCol, BCard, BCardBody, BAvatar, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton, BInputGroup, BInputGroupAppend, BImg,BMediaAside,
  BMediaBody,
  BCardText,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email, integer, min } from '@validations'
import axios from '@axios'

export default {
    name: 'UserInformation',
    components: {
        BRow, BCol, BCard, BCardBody, BAvatar, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton, BInputGroup, BInputGroupAppend,
        ValidationProvider,BImg,
        ValidationObserver,
        BMediaAside,
        BMediaBody,
        BCardText,
        BFormFile,
    },
    directives: {
        Ripple,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            firstName: null,
            firstName: null,
            email: null,
            phoneNumber: null,
            password: null,
            confirmPassword: null,
            avatar:null,
            avatar_new: null,
            // validation rules
            required,
            email,
            integer,
            min,
            customMessages: {
                required: 'Harus diisi',
                integer: 'Hanya bisa diisi angka 0-9',
                email: 'Email harus berupa alamat surel yang benar',
                min: 'No. handphone terlalu pendek',
                password: 'Password minimal 6 karakter dengan kombinasi huruf dan angka',
                confirmed: 'Konfirmasi password tidak sama'
            },
            confirmPasswordFieldType: 'password',
            emailError: null
        }
    },
    beforeMount() {
        let _ = this
        _.firstName = _.$store.state.digirans.firstName
        _.lastName = _.$store.state.digirans.lastName
        _.email = _.$store.state.digirans.email
        _.phoneNumber = _.$store.state.digirans.phoneNumber
        _.avatar = _.$store.state.digirans.avatar
    },
    methods: {
        userInformation() {
            let _ = this
            this.$refs.userInformation.validate().then(success => {
                if (success) {
                    let model = {
                        firstName: _.firstName,
                        lastName: _.lastName,
                        email: _.email,
                        phoneNumber: _.phoneNumber,
                        avatar : _.avatar
                    }

                    if ( _.password ) {
                        model = {
                            ...model,
                            password: _.password,
                            confirmPassword: _.confirmPassword
                        }
                    }
                    console.log(this.avatar, "ini");
                    // return
                    axios.put('/advertiser/update-account', model).then(resp => {
                        let _ = this
                        // Update vuex
                        _.$store.dispatch('digirans/UPDATE_PROFILE', resp.data.data)
                        // Update localstorage
                        let userData = JSON.parse(localStorage.getItem('userData'))
                            userData = {
                                ...userData,
                                email: _.email,
                                phoneNumber: _.phoneNumber,
                                advertiser: {
                                    ...userData.advertiser,
                                    firstName: _.firstName,
                                    lastName: _.lastName,
                                    fullName: _.firstName + ' ' + _.lastName
                                }
                            }
                            localStorage.setItem('userData', JSON.stringify(userData))
                        _.$bvToast.toast('Perubahan Berhasil Disimpan', {
                            title: "Sukses",
                            variant: "success",
                            solid: true,
                        })
                    }).catch(err => {
                        if ( err.response ) {
                            let errors = err.response.data.message.errors
                            errors.forEach(er => {
                                console.log(er)
                                if ( er.param == "email" ) {
                                    this.emailError = er.msg
                                }
                            })
                        }
                    })
                } else {
                    console.log('failed validation')
                }
            })
        },
        toggleConfirmPassword() {
            this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
        },
        uploadAvatar()
        {   
            if (typeof this.$refs.file.files[0] !== 'undefined') {
                let formData = new FormData();
                formData.append('file', this.$refs.file.files[0] != undefined ? this.$refs.file.files[0] : this.model.banner)
                axios
                .post( "/advertiser/update-account/upload-avatar", formData)
                .then((res) => {
                    this.avatar = res.data.data.avatar
                    console.log(this.avatar, "ini gaes");
                    this.userInformation()
                })
                .catch((e) => {
                    console.log("failure");
                });
            }else{
                this.userInformation();
            }
                       
        }
    },
    watch:{
        'avatar_new': function(newValue, oldValue) {
          base64Encode(newValue)
            .then(value => {
              this.avatar = value;
            })
            .catch(() => {
              this.avatar = null;
            });
      },
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        confirmPasswordToggleIcon() {
            return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        isRequired() {
            let _ = this
            return _.password ? "required|confirmed:Password" : "";
        }
    }
}
</script>